import React from 'react';
import Form from './Form';
import ChordRow from './ChordRow';
import {atOctave, chordSymbolToNotes, invert, keyNumber} from './chord';
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUser, updateProgression} from "./features/user/userSlice";
import {useParams} from "react-router-dom";

function Progression () {
  const currentUser = useSelector(selectCurrentUser);
  const params = useParams();
  const dispatch = useDispatch();

  if (!currentUser) return (<div>Loading...</div>);

  const progression = currentUser.progressions[params.id];

  if (!progression) return (<div>Page not found</div>);

  const addChord = chord => {
    const chords = [...progression.chords, chord];
    dispatch(updateProgression({...progression, chords}));
  };

  const removeChord = removeIndex => {
    const chords = progression.chords.filter((_, i) => i !== removeIndex);
    dispatch(updateProgression({...progression, chords}));
  };

  const invertChord = index => {
    const chords = [
      ...progression.chords.slice(0, index),
      { ...progression.chords[index], inversion: progression.chords[index].inversion + 1 },
      ...progression.chords.slice(index + 1),
    ];
    dispatch(updateProgression({...progression, chords}));
  };

  const increaseOctave = index => {
    const chords = [
      ...progression.chords.slice(0, index),
      { ...progression.chords[index], octave: progression.chords[index].octave + 1 },
      ...progression.chords.slice(index + 1),
    ];
    dispatch(updateProgression({...progression, chords}));
  };

  const decreaseOctave = index => {
    const chords = [
      ...progression.chords.slice(0, index),
      { ...progression.chords[index], octave: progression.chords[index].octave - 1 },
      ...progression.chords.slice(index + 1),
    ];
    dispatch(updateProgression({...progression, chords}));
  };

  const chordsForKeyboard = () => {
    const addKeyNumber = note => ({ ...note, number: keyNumber(note.note, note.octave) });
    const wrap = (chord, octave, inversion) => atOctave(octave, invert(chord, inversion)).map(addKeyNumber);

    return progression.chords.map(chord => {
      return {
        name: chord.chordSymbol,
        keys: wrap(chordSymbolToNotes(chord.chordSymbol).notes, chord.octave, chord.inversion)
      };
    });
  };

  return (
    <div className="container mt-5">
      <div className="row mb-5">
        <div className="col">
          <div className="border-bottom mb-4">
            <h1>{ progression.name }</h1>
          </div>
        </div>
      </div>

      {
        chordsForKeyboard().map((chord, i) => (
          <ChordRow
            key={ i }
            chord={ chord }
            onRemove={ () => removeChord(i) }
            onInvert={ () => invertChord(i) }
            onIncreaseOctave={ () => increaseOctave(i) }
            onDecreaseOctave={ () => decreaseOctave(i) }
          />
        ))
      }
      <div className="d-print-none">
        <Form onChordAdded={ addChord }/>
      </div>
    </div>
  );

}

export default Progression;
