import React, {Component} from 'react';
import Keyboard from './Keyboard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltLeft, faLongArrowAltRight, faRandom, faTrash} from '@fortawesome/free-solid-svg-icons';

const iconClasses = "d-inline d-md-none";
const textClasses = "d-none d-md-inline";

class ChordRow extends Component {

  remove = e => {
    e.preventDefault();
    this.props.onRemove();
  };

  invert = e => {
    e.preventDefault();
    this.props.onInvert();
  };

  increaseOctave = e => {
    e.preventDefault();
    this.props.onIncreaseOctave();
  };

  decreaseOctave = e => {
    e.preventDefault();
    this.props.onDecreaseOctave();
  };

  render () {
    return (
      <div>
        <div className="d-none d-print-block mb-3" style={ { pageBreakInside: "avoid" } }>
          <p className="h2">{ this.props.chord.name }</p>
          <Keyboard selectedKeys={ this.props.chord.keys }/>
        </div>
        <div className="d-print-none">
          <div className="card mb-4">
            <div className="card-header">
              <ul className="nav float-right">
                <li className="nav-item">
                  <button className="btn btn-link nav-link" onClick={ this.remove }>
                    <FontAwesomeIcon icon={ faTrash } className={ iconClasses }/>
                    <span className={textClasses}>Remove</span>
                  </button>
                </li>
                <li className="nav-item">
                  <button className="btn btn-link nav-link" onClick={ this.invert }>
                    <FontAwesomeIcon icon={ faRandom } className={ iconClasses }/>
                    <span className={textClasses}>Invert</span>
                  </button>
                </li>
                <li className="nav-item">
                  <button className="btn btn-link nav-link" onClick={ this.decreaseOctave }>
                    <FontAwesomeIcon icon={ faLongArrowAltLeft } className={ iconClasses }/>
                    <span className={textClasses}>Decrease Octave</span>
                  </button>
                </li>
                <li className="nav-item">
                  <button className="btn btn-link nav-link" onClick={ this.increaseOctave }>
                    <FontAwesomeIcon icon={ faLongArrowAltRight } className={ iconClasses }/>
                    <span className={textClasses}>Increase Octave</span>
                  </button>
                </li>
              </ul>
              { this.props.chord.name }
            </div>
            <div className="card-body text-center">
              <Keyboard selectedKeys={ this.props.chord.keys }/>
            </div>
          </div>

        </div>
      </div>
    );
  }

}

export default ChordRow;
