import React, {useEffect} from 'react';
import './App.css';
import Progressions from './Progressions';
import Progression from './Progression';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Link, Route} from "react-router-dom";
import {auth, GoogleAuthProvider} from "./services/firebase";
import {useDispatch, useSelector} from "react-redux";
import {login, selectCurrentUser, setCurrentUser} from "./features/user/userSlice";

function App () {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        dispatch(login(user.toJSON()));
      } else {
        dispatch(setCurrentUser(null));
      }
    });
  }, [dispatch]);

  return (
    <Router>
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <Link className="navbar-brand" to="/">Piano Chords</Link>
            {
              currentUser && (
                <>
                  <button className={"btn btn-link"} onClick={ e => auth.signOut() }>Sign Out</button>
                </>
              )
            }
            {
              !currentUser && (
                <>
                  <button className={"btn btn-link"} onClick={ e => auth.signInWithRedirect(GoogleAuthProvider) }>
                    Sign In
                  </button>
                </>
              )
            }
          </div>
        </nav>
        {
          currentUser && (
            <>
              <Route exact path="/" component={ Progressions }/>
              <Route exact path="/progressions/:id" component={ Progression }/>
            </>
          )
        }
        {
          !currentUser && (
            <div className="container text-center mt-5">
              <div className="jumbotron"><p className="lead">Sign in to view your progressions</p></div>
            </div>
          )
        }
      </div>
    </Router>
  );

}

export default App;
