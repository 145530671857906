import React, {useState} from 'react';

function Form ({ onChordAdded }) {

  const [chordSymbol, setChordSymbol] = useState("");
  const [inversion, setInversion] = useState(0);
  const [octave, setOctave] = useState(4);

  const onSubmit = (e) => {
    e.preventDefault();
    onChordAdded({
      chordSymbol,
      inversion,
      octave,
    });
    setChordSymbol("");
  };

  return (
    <div className="pb-5 mb-5">
      <h1>Add a Chord</h1>

      <form onSubmit={ onSubmit } className="form-inline">
        <div className="form-group mr-1">
          <label htmlFor="chord-symbol" className="mr-1">Chord Symbol</label>
          <input
            name="chordSymbol"
            id="chord-symbol"
            className="form-control"
            autoFocus
            type="text"
            placeholder="C-7"
            value={ chordSymbol }
            onChange={ e => setChordSymbol(e.target.value) }
          />
        </div>
        <div className="form-group mr-1">
          <label htmlFor="inversion" className="mr-1">Inversion</label>
          <input
            name="inversion"
            id="inversion"
            className="form-control"
            type="number"
            min="0"
            max="6"
            value={ inversion }
            onChange={ e => setInversion(e.target.value ? +e.target.value : null) }
          />
        </div>
        <div className="form-group mr-1">
          <label htmlFor="octave" className="mr-1">Octave</label>
          <input
            name="octave"
            id="octave"
            className="form-control"
            type="number"
            min="0"
            max="8"
            value={ octave }
            onChange={ e => setOctave(e.target.value ? +e.target.value : null) }
          />
        </div>
        <div className="form-group">
          <input type="submit" className="btn btn-primary"/>
        </div>
      </form>
    </div>
  );
}

export default Form;
