import {createSlice} from '@reduxjs/toolkit';
import {firestore} from "../../services/firebase";

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: null,
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setProgression: (state, action) => {
      state.currentUser.progressions[action.payload.id] = action.payload;
    },
  },
});

export const { setCurrentUser, setProgression } = userSlice.actions;

export const login = (user) => async dispatch => {
  const progressionItem = localStorage.getItem("progressions");
  let localProgressions = {};
  if (progressionItem) {
    localProgressions = JSON.parse(progressionItem)
      .map(progression => {
        const chordItem = localStorage.getItem(progression.id);
        if (chordItem) return { ...progression, chords: JSON.parse(chordItem) };
        return null;
      })
      .filter(progression => progression)
      .reduce((result, progression) => {
        result[progression.id] = progression;
        return result;
      }, {});
  }

  const doc = await firestore.collection(`/users`).doc(user.uid).get();
  if (doc.exists) {
    if(localProgressions) {
      const existingProgressions = doc.data().progressions;
      const updates = Object.entries(localProgressions).reduce((result, [id, progression]) => {
        const key = existingProgressions.hasOwnProperty(id) ? `${id}_copy` : id;
        result[`progressions.${ key }`] = {...progression, id: key};
        return result
      }, {});
      await firestore.collection(`/users`).doc(user.uid).update(updates);
      const refreshed = await firestore.collection(`/users`).doc(user.uid).get();
      dispatch(setCurrentUser(refreshed.data()));
    } else {
      dispatch(setCurrentUser(doc.data()));
    }
  } else {
    const data = {
      uid: user.uid,
      email: user.email,
      progressions: localProgressions
    };
    await firestore.collection(`/users`).doc(user.uid).set(data);
    dispatch(setCurrentUser(data));
  }
  Object.values(localProgressions).forEach(progression => localStorage.removeItem(progression.id));
  localStorage.removeItem("progressions");
};

export const updateProgression = (progression, history) => async (dispatch, getState) => {
  const state = getState();
  const currentUser = state.user.currentUser;

  const userDoc = await firestore.collection(`/users`).doc(currentUser.uid);
  await userDoc.update({ [`progressions.${ progression.id }`]: progression });
  dispatch(setProgression(progression));
  if (history) history.push(`/progressions/${ progression.id }`);
};

export const selectCurrentUser = state => state.user.currentUser;

export default userSlice.reducer;
