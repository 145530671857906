import React, { Component } from 'react';

const whiteKeyWidth = 20
const blackKeyWidth = whiteKeyWidth * 0.57
const whiteKeyHeight = whiteKeyWidth * 5
const blackKeyHeight = whiteKeyHeight * 0.64

// const selectedWhiteColor = "#75AEA8"
const selectedWhiteColor = "red"
const selectedBlackColor = selectedWhiteColor

const white  = "white"
const black  = "black"

const outOfRangeWhiteColor = white
const outOfRangeBlackColor = black

const blackKeyStarts = [
  whiteKeyWidth * 0.64, // 9  for a width of 14
  whiteKeyWidth * 1.78, // 25 for a width of 14
  whiteKeyWidth * 3.64, // 51 for a width of 14
  whiteKeyWidth * 4.71, // 66 for a width of 14
  whiteKeyWidth * 5.78, // 81 for a width of 14
]

class Keyboard extends Component {

  keys = () => {
    let keys = [
      {color: 'white', x: whiteKeyWidth * 0, evenBorder: 'low'},
      {color: 'black', x: blackKeyStarts[4] - (whiteKeyWidth * 5)},
      {color: 'white', x: whiteKeyWidth * 1},
    ]

    let groupStart = whiteKeyWidth * 2

    for (let index = 0; index < 7; index++) {
      keys.push(
        {color: 'white', x: groupStart + whiteKeyWidth * 0, evenBorder: 'low'},
        {color: 'black', x: groupStart + blackKeyStarts[0]},
        {color: 'white', x: groupStart + whiteKeyWidth * 1},
        {color: 'black', x: groupStart + blackKeyStarts[1]},
        {color: 'white', x: groupStart + whiteKeyWidth * 2, evenBorder: 'high'},
        {color: 'white', x: groupStart + whiteKeyWidth * 3, evenBorder: 'low'},
        {color: 'black', x: groupStart + blackKeyStarts[2]},
        {color: 'white', x: groupStart + whiteKeyWidth * 4},
        {color: 'black', x: groupStart + blackKeyStarts[3]},
        {color: 'white', x: groupStart + whiteKeyWidth * 5},
        {color: 'black', x: groupStart + blackKeyStarts[4]},
        {color: 'white', x: groupStart + whiteKeyWidth * 6, evenBorder: 'high'},
      )
      groupStart = groupStart + whiteKeyWidth * 7
    }

    keys.push({color: 'white', x: groupStart + whiteKeyWidth * 0, evenBorder: 'high'})

    return keys
  }

  render() {
    const whiteKeys = []
    const blackKeys = []
    const keyNumbers = this.props.selectedKeys.map(n => n.number)
    const max = Math.max(...keyNumbers)
    const min = Math.min(...keyNumbers)
    const selectedKeyNumbers = new Set(keyNumbers)

    const keys = this.keys()
    const lowerKey = keys.reduce((prev, curr, i) => i < min && curr.evenBorder === 'low' ? i : prev, -1)
    const higherKey = keys.findIndex((k, i) => i > max && k.evenBorder === 'high')

    keys.forEach((key, i) => {
      const inRange = i < lowerKey || i > higherKey

      if (key.color === 'white') {
        whiteKeys.push(
          <rect
            key={i}
            x={key.x}
            y="0" width={whiteKeyWidth}
            height={whiteKeyHeight}
            fill={ inRange ? outOfRangeWhiteColor : (selectedKeyNumbers.has(i) ? selectedWhiteColor : white) }
            stroke="black" />
        )
      }

      if (key.color === 'black') {
        blackKeys.push(
          <rect
            key={i}
            x={key.x}
            y="0"
            width={blackKeyWidth}
            height={blackKeyHeight}
            fill={ inRange ? outOfRangeBlackColor : (selectedKeyNumbers.has(i) ? selectedBlackColor : black) }
            className="black-key"
            stroke="black" />
        )
      }
    })

    const keyElements = [...whiteKeys, ...blackKeys]

    const totalWidth = whiteKeys.length * whiteKeyWidth

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox={`0 0 ${totalWidth} ${whiteKeyHeight}`}
        style={ {width: `${totalWidth}px`, height: `${whiteKeyHeight}px` }}>
        { keyElements }
      </svg>
    );
  }

}

export default Keyboard;
