import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDr23Nr6kjNiRxJPhRM7NV4K12zUNx3kOE",
    authDomain: "piano-chords-40029.firebaseapp.com",
    databaseURL: "https://piano-chords-40029.firebaseio.com",
    projectId: "piano-chords-40029",
    storageBucket: "piano-chords-40029.appspot.com",
    messagingSenderId: "170446334379",
    appId: "1:170446334379:web:4aab334480a92e5063caee",
    measurementId: "G-KBZQWLSBSL"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const GoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const firestore = firebase.firestore();

if (window.location.hostname === "localhost") {
    firestore.settings({
        host: "localhost:8080",
        ssl: false
    });
}
