import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {updateProgression} from "./features/user/userSlice";
import {useHistory} from "react-router-dom";

function ProgressionForm () {
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (e) => {
    e.preventDefault();
    const id = name.toLowerCase().split(/[^a-z0-9_]/).join("_").split(/_+/).join("_");
    const progression = { id, name, chords: [] };
    dispatch(updateProgression(progression, history));
  };

  return (
    <div className="pb-2 mb-2">
      <h3>Create a Progression</h3>
      <div className="row">
        <div className="col-6">
          <form onSubmit={ onSubmit }>
            <div className="form-group mr-1">
              <label htmlFor="chord-symbol" className="mr-1">Name</label>
              <input
                name="name"
                id="name"
                className="form-control"
                autoFocus
                type="text"
                placeholder="Enter Song Name"
                value={ name }
                onChange={ e => setName(e.target.value) }
              />
            </div>
            <div className="form-group">
              <input type="submit" className="btn btn-primary"/>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ProgressionForm;
