import React from 'react';
import ProgressionForm from './ProgressionForm';
import {Link} from 'react-router-dom';
import {selectCurrentUser} from "./features/user/userSlice";
import {useSelector} from "react-redux";

function Progressions () {

  const currentUser = useSelector(selectCurrentUser);
  // const progressionString = localStorage.getItem('progressions')
  // if (progressionString) {
  //   try {
  //     this.state = { progressions: JSON.parse(progressionString) }
  //   } catch (e) {
  //   }
  // }

  const progressions = currentUser ? Object.values(currentUser.progressions) : [];
  const progressionItems = progressions.map((progression, i) => {
    return (
      <li key={ i }><Link to={ `/progressions/${ progression.id }` }>{ progression.name }</Link></li>
    );
  });

  return (
    <div className="container mt-5 pt-5">
      <div className="row">
        <div className="col">
          <div className="border-bottom mb-4">
            <h1>Progressions</h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ProgressionForm/>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {
            progressions.length ? <ul>{ progressionItems }</ul> :
              <div className="jumbotron"><p className="lead">Create progressions above</p></div>
          }
        </div>
      </div>
    </div>
  );
}

export default Progressions;
