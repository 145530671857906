import {solfegeTable} from "./solfege-table";

export const solfegeToNote = (solfegeNotes, startingNote) => {
  return solfegeNotes.map(solfegeNote => solfegeTable[startingNote][solfegeNote]);
};

export const invert = (notes, numberOfTimes = 1) => {
  let result = [...notes];
  for (let i = 0; i < numberOfTimes; i++) {
    result = [
      ...result.slice(1),
      result[0]
    ];
  }
  return result;
};

export const atOctave = (startOctave, notes) => {
  const noteNames = ["C", "D", "E", "F", "G", "A", "B"];
  const result = [];
  let prevIndex;
  let octave = startOctave;
  notes.forEach(note => {
    const index = noteNames.indexOf(note[0]);
    if (prevIndex !== undefined && index < prevIndex) octave++;
    prevIndex = index;
    result.push({ note, octave });
  });
  return result;
};

export const keyNumber = (note, octave) => {
  const relativeNumbers = { C: 0, D: 2, E: 4, F: 5, G: 7, A: 9, B: 11 };
  const letter = note[0];
  const distanceFromC = relativeNumbers[letter];
  const startPos = octave * 12 + distanceFromC - 9;
  const sharps = note.split("#").length - 1;
  const flats = note.split("b").length - 1;
  return startPos + sharps - flats;
};

const chordTypes = {
  major: ['do', 'mi', 'sol'],
  major7: ['do', 'mi', 'sol', 'ti'],
  dominant7: ['do', 'mi', 'sol', 'te'],
  minor: ['do', 'me', 'sol'],
  minor7: ['do', 'me', 'sol', 'te'],
  minor6: ['do', 'me', 'sol', 'la'],
  minor7FlatFive: ['do', 'me', 'se', 'te'],
  diminished: ['do', 'me', 'se'],
  diminished7: ['do', 'me', 'se', 'la'],
  augmented: ['do', 'mi', 'si'],
  augmented7: ['do', 'mi', 'si', 'te'],
};

export const chordSymbolToNotes = (chord) => {
  const mappings = {
    "M": 'major',
    "MAJ": 'major',
    "Maj": 'major',

    "m": 'minor',
    "-": 'minor',

    "-6": 'minor6',
    "-7": 'minor7',
    "-7b5": 'minor7FlatFive',

    "m6": 'minor6',
    "m7": 'minor7',
    "m7b5": 'minor7FlatFive',

    "7": 'dominant7',

    "M7": 'major7',
    "Maj7": 'major7',
    "MAJ7": 'major7',

    "aug": 'augmented',
    "+": 'augmented',
    "7#5": 'augmented7',

    "dim": 'diminished',
    "dim7": 'diminished7',
  };

  let noteName;
  let chordTypeIndex;

  if (chord[1] === '#' || chord[1] === 'b') {
    noteName = chord[0].toUpperCase() + chord[1];
    chordTypeIndex = 2;
  } else {
    noteName = chord[0].toUpperCase();
    chordTypeIndex = 1;
  }

  const rest = chord.slice(chordTypeIndex);
  const solfege = chordTypes[mappings[rest] || mappings.M];
  const notes = solfegeToNote(solfege, noteName);

  return { name: chord, root: noteName, solfege, notes };
};
