export const solfegeTable = {
  Cb: { do: 'Cb', di: 'C', ra: 'Dbb', re: 'Db', ri: 'D', me: 'Ebb', mi: 'Eb', fa: 'Fb', fi: 'F', se: 'Gbb', sol: 'Gb', si: 'G', le: 'Abb', la: 'Ab', li: 'A', te: 'Bbb', ti: 'Bb' },
  C: { do: 'C', di: 'C#', ra: 'Db', re: 'D', ri: 'D#', me: 'Eb', mi: 'E', fa: 'F', fi: 'F#', se: 'Gb', sol: 'G', si: 'G#', le: 'Ab', la: 'A', li: 'A#', te: 'Bb', ti: 'B' },
  'C#': { do: 'C#', di: 'C##', ra: 'D', re: 'D#', ri: 'D##', me: 'E', mi: 'E#', fa: 'F#', fi: 'F##', se: 'G', sol: 'G#', si: 'G##', le: 'A', la: 'A#', li: 'A##', te: 'B', ti: 'B#' },
  Db: { do: 'Db', di: 'D', ra: 'Ebb', re: 'Eb', ri: 'E', me: 'Fb', mi: 'F', fa: 'Gb', fi: 'G', se: 'Abb', sol: 'Ab', si: 'A', le: 'Bbb', la: 'Bb', li: 'B', te: 'Cb', ti: 'C' },
  D: { do: 'D', di: 'D#', ra: 'Eb', re: 'E', ri: 'E#', me: 'F', mi: 'F#', fa: 'G', fi: 'G#', se: 'Ab', sol: 'A', si: 'A#', le: 'Bb', la: 'B', li: 'B#', te: 'C', ti: 'C#' },
  'D#': { do: 'D#', di: 'D##', ra: 'E', re: 'E#', ri: 'E##', me: 'F#', mi: 'F##', fa: 'G#', fi: 'G##', se: 'A', sol: 'A#', si: 'A##', le: 'B', la: 'B#', li: 'B##', te: 'C#', ti: 'C##' },
  Eb: { do: 'Eb', di: 'E', ra: 'Fb', re: 'F', ri: 'F#', me: 'Gb', mi: 'G', fa: 'Ab', fi: 'A', se: 'Bbb', sol: 'Bb', si: 'B', le: 'Cb', la: 'C', li: 'C#', te: 'Db', ti: 'D' },
  E: { do: 'E', di: 'E#', ra: 'F', re: 'F#', ri: 'F##', me: 'G', mi: 'G#', fa: 'A', fi: 'A#', se: 'Bb', sol: 'B', si: 'B#', le: 'C', la: 'C#', li: 'C##', te: 'D', ti: 'D#' },
  'E#': { do: 'E#', di: 'E##', ra: 'F#', re: 'F##', ri: 'F###', me: 'G#', mi: 'G##', fa: 'A#', fi: 'A##', se: 'B', sol: 'B#', si: 'B##', le: 'C#', la: 'C##', li: 'C###', te: 'D#', ti: 'D##' },
  Fb: { do: 'Fb', di: 'F', ra: 'Gbb', re: 'Gb', ri: 'G', me: 'Abb', mi: 'Ab', fa: 'Bbb', fi: 'Bb', se: 'Cbb', sol: 'Cb', si: 'C', le: 'Dbb', la: 'Db', li: 'D', te: 'Ebb', ti: 'Eb' },
  F: { do: 'F', di: 'F#', ra: 'Gb', re: 'G', ri: 'G#', me: 'Ab', mi: 'A', fa: 'Bb', fi: 'B', se: 'Cb', sol: 'C', si: 'C#', le: 'Db', la: 'D', li: 'D#', te: 'Eb', ti: 'E' },
  'F#': { do: 'F#', di: 'F##', ra: 'G', re: 'G#', ri: 'G##', me: 'A', mi: 'A#', fa: 'B', fi: 'B#', se: 'C', sol: 'C#', si: 'C##', le: 'D', la: 'D#', li: 'D##', te: 'E', ti: 'E#' },
  Gb: { do: 'Gb', di: 'G', ra: 'Abb', re: 'Ab', ri: 'A', me: 'Bbb', mi: 'Bb', fa: 'Cb', fi: 'C', se: 'Dbb', sol: 'Db', si: 'D', le: 'Ebb', la: 'Eb', li: 'E', te: 'Fb', ti: 'F' },
  G: { do: 'G', di: 'G#', ra: 'Ab', re: 'A', ri: 'A#', me: 'Bb', mi: 'B', fa: 'C', fi: 'C#', se: 'Db', sol: 'D', si: 'D#', le: 'Eb', la: 'E', li: 'E#', te: 'F', ti: 'F#' },
  'G#': { do: 'G#', di: 'G##', ra: 'A', re: 'A#', ri: 'A##', me: 'B', mi: 'B#', fa: 'C#', fi: 'C##', se: 'D', sol: 'D#', si: 'D##', le: 'E', la: 'E#', li: 'E##', te: 'F#', ti: 'F##' },
  Ab: { do: 'Ab', di: 'A', ra: 'Bbb', re: 'Bb', ri: 'B', me: 'Cb', mi: 'C', fa: 'Db', fi: 'D', se: 'Ebb', sol: 'Eb', si: 'E', le: 'Fb', la: 'F', li: 'F#', te: 'Gb', ti: 'G' },
  A: { do: 'A', di: 'A#', ra: 'Bb', re: 'B', ri: 'B#', me: 'C', mi: 'C#', fa: 'D', fi: 'D#', se: 'Eb', sol: 'E', si: 'E#', le: 'F', la: 'F#', li: 'F##', te: 'G', ti: 'G#' },
  'A#': { do: 'A#', di: 'A##', ra: 'B', re: 'B#', ri: 'B##', me: 'C#', mi: 'C##', fa: 'D#', fi: 'D##', se: 'E', sol: 'E#', si: 'E##', le: 'F#', la: 'F##', li: 'F###', te: 'G#', ti: 'G##' },
  Bb: { do: 'Bb', di: 'B', ra: 'Cb', re: 'C', ri: 'C#', me: 'Db', mi: 'D', fa: 'Eb', fi: 'E', se: 'Fb', sol: 'F', si: 'F#', le: 'Gb', la: 'G', li: 'G#', te: 'Ab', ti: 'A' },
  B: { do: 'B', di: 'B#', ra: 'C', re: 'C#', ri: 'C##', me: 'D', mi: 'D#', fa: 'E', fi: 'E#', se: 'F', sol: 'F#', si: 'F##', le: 'G', la: 'G#', li: 'G##', te: 'A', ti: 'A#' },
  'B#': { do: 'B#', di: 'B##', ra: 'C#', re: 'C##', ri: 'C###', me: 'D#', mi: 'D##', fa: 'E#', fi: 'E##', se: 'F#', sol: 'F##', si: 'F###', le: 'G#', la: 'G##', li: 'G###', te: 'A#', ti: 'A##' }
};
